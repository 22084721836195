import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, List, ListItem, ListItemText, Paper, Typography, Snackbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import {  setCookie } from '../dwi-functions';
import Alert from "@material-ui/lab/Alert";
import ClipboardJS from 'clipboard';
import appVars from '../appVars.json';

//setCookie('validation',false,30);


const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    head: {
        borderBottom: "1px solid"
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.light
    },
    paper: {
        padding: "1em",
        marginTop: "1em",
        maxWidth: "90%"
    },
    divider: {
        marginBottom: "1em",
        marginTop: "1em"
    }
}));



const Dns = (props) => {
    const classes = useStyles();
    const [project, setProject] = useState([]);
    const [copySuccess, setCopySuccess] = useState("");
    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [hasError, setErrors] = useState(false);
    const [validated, setValidated] = useState('Mark Complete');
    const [disabled, setDisabled] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setErrors(false);
        setCopySuccessShow(false);
    };




    async function fetchData(id, postData) {
        const res = await fetch(id,{
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
              'Content-Type': 'application/json'
            }
          });
        //console.log(res)
        //res.json().then((res) => setProject(res));
    }


    useEffect(() => {

        var clipboard = new ClipboardJS('#copy-dns');
        clipboard.on("success", function (e) {
            setCopySuccess(e.action + ": " + e.text);
            setCopySuccessShow(true);
            e.clearSelection();
        });
        setProject(props);
    }, []);

    return (
        <div >

            <Grid container spacing={3} >
                <Grid item xl="auto" xs={12}>
                    <Typography variant="h6">DNS Method</Typography>
                    <Typography variant="body2">Add a DNS TXT record to your domain host to prove that you own the domain. Most domain hosts allow this, but the specific steps for each service will vary. If you need help, please contact your domain host's support team or check their documentation.</Typography>
                </Grid>

            </Grid>
            <Divider className={classes.divider}></Divider>

            <Grid container spacing={3} >

                <Grid item lg={12} xl={12} xs={12}>
                    <List>
                        <ListItem>
                            <ListItemText>
                                1. Login to you domain registrar and visit the DNS records section. {(props.registrar !== '' ? 'We have determined that your domain registrar is ' + props.registrar : '')}
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                2. Add this TXT record in your DNS configuration:
                    <Paper elevation={3} className={classes.paper}>
                                    <code id="dns">facebook-domain-verification={props.fbVerify}</code>
                                    <Divider className={classes.divider}></Divider><Button id="copy-dns" color="secondary" variant="outlined" size="small" data-clipboard-target="#dns">
                                        Copy to clipboard
                    </Button>
                                </Paper>

                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                3. Add @ in the Host field (if your domain host requires it)
                </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                4. Wait until your DNS configuration changes have spread across your website's domain servers (this may take up to 72 hours)
                </ListItemText>
                        </ListItem>
                    </List>
                    <Divider className={classes.divider}></Divider>
                    <Button variant="contained" disabled={disabled} color="primary" onClick={(e) => {
                        var data = {
                            "sender": "Facebook Validation: DNS ",
                            "to": "donnie@digitalwerksinnovations.com",
                            "subject": "DNS Verification:" + props.name,
                            "message": "<h1>" + props.name + "</h1> has completed the DNS Validation method for Facebook @ " + Date.now()
                        };

                       fetchData(appVars.smtpUrl,data);
                       setValidated("Validation Pending...");
                       setDisabled(true);
                       setCookie('validation',true,30);
                       setCopySuccess('Your validation request has been sent. You will be notified if the validation fails.');
                       setCopySuccessShow(true);
                       //console.log(data);
                    }}>{validated}</Button>
                </Grid>

            </Grid>

            <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    The data can not be pulled at this time.
          <Button color="primary" onClick={window.location.reload}>
                        Refresh
          </Button>
                </Alert>
            </Snackbar>

            <Snackbar
                open={copySuccessShow}
                autoHideDuration={10000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    {copySuccess}
                </Alert>
            </Snackbar>

        </div>
    );
};

Dns.propTypes = {

};

export default Dns;
