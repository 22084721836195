import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, List, ListItem, ListItemText, Typography, Paper,Snackbar} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import {setCookie } from '../dwi-functions';
import Link from '@material-ui/core/Link';
import ClipboardJS from 'clipboard';
import appVars from '../appVars.json';
import Alert from "@material-ui/lab/Alert";



const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    head: {
        borderBottom: "1px solid"
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.light
    },
    paper: {
        padding: "1em",
        marginTop: "1em",
        maxWidth: "70%"
    },
    divider: {
        marginBottom: "1em",
        marginTop: "1em"
    }
}));


const Meta = (props) => {

    const classes = useStyles();
    const [project, setProject] = useState([]);
    const [copySuccess, setCopySuccess] = useState("");
    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [hasError, setErrors] = useState(false);
    const [validated, setValidated] = useState('Mark Complete');
    const [disabled, setDisabled] = useState(false);


    async function fetchData(id, postData) {
        const res = await fetch(id, {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        //console.log(res)
        //res.json().then((res) => setProject(res));
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setErrors(false);
        setCopySuccessShow(false);
    };



    useEffect(() => {

        var clipboard = new ClipboardJS('#copy-meta');
        clipboard.on("success", function (e) {
            setCopySuccess(e.action + ": " + e.text);
            setCopySuccessShow(true);
            e.clearSelection();
        });
        setProject(props);
    }, []);

    return (
        <div >

            <Grid container spacing={3} >
                <Grid item xl="auto" xs={12}>
                    <Typography variant="h6">Meta-tag Verification</Typography>
                   Set the meta-tag containing the verification code into the &lt;head&gt; section of your website's homepage.

                </Grid>

            </Grid>
            <Divider className={classes.divider}></Divider>

            <Grid container spacing={3} >

                <Grid item lg={12} xl={12}>
                    <List>
                        <ListItem>
                            <ListItemText>
                                1. Add this meta-tag to the &lt;head&gt; section in the HTML of your domain homepage, <Link target="_BLANK" href={props.siteUrl} >{props.siteUrl}</Link>:
                    <Paper elevation={3} className={classes.paper}>
                                    <code id="meta">&lt;meta name="facebook-domain-verification" content="{props.fbVerify}" /&gt;</code>
                                    <Divider className={classes.divider}></Divider><Button id="copy-meta" color="secondary" variant="outlined" size="small" data-clipboard-target="#meta">
                                        Copy to clipboard
                    </Button>
                                </Paper>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                2. Confirm that the meta-tag is visible by visiting <Link target="_BLANK" href={props.siteUrl} >{props.siteUrl}</Link> and viewing the HTML source.
                    </ListItemText>
                        </ListItem>

                    </List>
                    <Button variant="contained" disabled={disabled} color="primary" onClick={(e) => {
                        var data = {
                            "sender": "Facebook Validation: META ",
                            "to": "donnie@digitalwerksinnovations.com",
                            "subject": "META Verification:" + props.name,
                            "message": "<h1>" + props.name + "</h1> has completed the META Validation method for Facebook @ " + Date.now()
                        };

                        fetchData(appVars.smtpUrl, data);
                        setValidated("Validation Pending...");
                        setDisabled(true);
                        setCookie('validation', true, 30);
                        setCopySuccess('Your validation request has been sent. You will be notified if the validation fails.');
                        setCopySuccessShow(true);
                        //console.log(data);
                    }}>{validated}</Button>
                </Grid>

            </Grid>

            <Snackbar
                open={copySuccessShow}
                autoHideDuration={10000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    {copySuccess}
                </Alert>
            </Snackbar>

        </div>
    );
};

Meta.propTypes = {

};

export default Meta;
