import React, { useState, useEffect } from "react";
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from './Navbar'
import 'typeface-roboto';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import themeFile from './theme.json';
import {  BrowserRouter, Route, Switch } from 'react-router-dom';
import DashBoard from './DashBoard';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Dns from './dns';
import appVars from './appVars.json';
import { getUrlParameter, setCookie } from './dwi-functions';
import Meta from './meta';
import {  Typography } from "@material-ui/core";

var a = getUrlParameter('id');
var c = '';
var sl =getUrlParameter('sl');


if (sl !== '') {
  sessionStorage.setItem('sl', sl);
}

//alert(a);
if (a !== '') {
  sessionStorage.setItem('fb-verify', a);
  setCookie('fb-verify', a, 30);
  c = a;
} else {
  c = sessionStorage.getItem('fb-verify');
  //c = getCookie('fb-verify');
  if( c===null){
    window.location.href= 'https://api.digitalwerksautomation.com/?id=ac0b5617-4d08-4263-84c1-f6d1c4ae88d7';
  }
}




const url = appVars.api_url+"/tagmanagers/" + c;

console.log(url);

const theme = createMuiTheme(themeFile);
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {

    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  topCopy:{
    marginBottom: theme.spacing(3)
  }
}));



const site = {
  title: ''
}


function App() {

  const classes = useStyles();
  const [project, setProject] = useState({
    name:'Looking up name....',
    fbVerify: 'Looking up id....',
    siteUrl: 'Looking up URL....',
    registrar: 'Looking up Registra....'
  });




  async function fetchData(id) {
    const res = await fetch(id);
    //console.log(res)
    res.json().then((res) => setProject(res));
  }

  useEffect(() => {
    fetchData(url);
    document.title = appVars.application ;
  //  console.log(project);
  }, []);




  return (

    <MuiPickersUtilsProvider utils={MomentUtils}>
      <BrowserRouter basename="/">
        <div className={classes.root}>
          <Switch>

            <ThemeProvider theme={theme}>

              <Navbar clName={project.name} siteTitle={site.title} logo={project.logo} sl={sl}></Navbar>




              <main className={classes.content}>
                <div className={classes.toolbar} />

                <Typography className={classes.topCopy} variant="body1">In response to iOS 14 Release changes, we will start processing pixel conversion events from iOS 14 devices using Aggregated Event Measurement. This will support your efforts to preserve user privacy and help you run effective campaigns.</Typography>
                <Typography className={classes.topCopy} variant="body1">You may need to verify your website’s domain to help avoid any future disruption of your website campaigns. Domain verification must be done for the effective top level domain. It's free to verify your domains. There are 3 domain verification methods: HTML file upload, DNS TXT record, or META-TAG. It is only necesary to complete one(1) of the listed methods.</Typography>

               <Typography color="primary" gutterBottom>Verification : {project.fbVerify}</Typography>




                <Route path="/" exact >
               
                  <DashBoard  fbVerify={project.fbVerify} siteUrl={project.siteUrl} name={project.name}></DashBoard>
                 
                </Route>
                <Route path="/dns" exact >
                    <Dns registrar={project.registrar} fbVerify={project.fbVerify} name={project.name} siteUrl={project.siteUrl} ></Dns>
                </Route>
                <Route path="/meta" exact >
                  <Meta fbVerify={project.fbVerify} siteUrl={project.siteUrl} name={project.name}></Meta>
                </Route>

              </main>
         
            </ThemeProvider>
          </Switch>

        </div>
       
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  );
}

export default App;
