import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, List, ListItem, ListItemText, Typography, Snackbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import {setCookie} from '../dwi-functions';
import Link from '@material-ui/core/Link';
import appVars from "../appVars.json";
import Alert from "@material-ui/lab/Alert";



function downloadText(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
  }

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    head: {
        borderBottom: "1px solid"
    },
    cardHeader: {
        backgroundColor: theme.palette.primary.light
    },
    paper: {
        padding: "1em"
    },
    divider: {
        marginBottom: "1em",
        marginTop: "1em"
    }
}));


const DashBoard = (props) => {
    const classes = useStyles();
    const [project, setProject] = useState([props]);
    const [validated, setValidated] = useState('Mark Complete');
    const [disabled, setDisabled] = useState(false);
    const [copySuccess, setCopySuccess] = useState("");
    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [hasError, setErrors] = useState(false);

  async function fetchData(id, postData) {
    const res = await fetch(id,{
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'Content-Type': 'application/json'
        }
      });
    //console.log(res)
    //res.json().then((res) => setProject(res));
}
const handleClose = (event, reason) => {
    if (reason === "clickaway") {
        return;
    }
    setErrors(false);
    setCopySuccessShow(false);
};
  useEffect(() => {
   setProject(props);
  }, []);

    return (
        <div>

            <Grid container spacing={3} >
                <Grid item xl="auto" xs={12}>
                   <Typography variant="h6">FTP File Upload Method</Typography>
                   Upload an HTML verification file to your website. To do this you need admin access to your website's root folder. This will verify your domain immediately.
                </Grid>

            </Grid>
            <Divider className={classes.divider}></Divider>

            <Grid container spacing={3} >

                <Grid item lg={12} xl={12}>
            <List>
                <ListItem>
                    <ListItemText>
                        1. Download the HTML verification file <Link href="#" 
                          onClick={() => {
                            downloadText(props.fbVerify+'.html',props.fbVerify);
                          }}
                        >{props.fbVerify}.html</Link>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        2. Upload this file to the root directory of <Link  target="_BLANK" href={props.siteUrl} >{props.siteUrl}</Link>.
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        3. Confirm that it's uploaded by visiting link to <Link target="_BLANK" href={props.siteUrl + '/'+ props.fbVerify+'.html'}>{props.siteUrl}/{props.fbVerify}.html</Link>
                    </ListItemText>
                </ListItem>
            </List>
            
            <Button variant="contained" disabled={disabled} color="primary" onClick={(e) => {
                        var data = {
                            "sender": "Facebook Validation: FTP ",
                            "to": "donnie@digitalwerksinnovations.com",
                            "subject": "FTP Verification:" + props.name,
                            "message": "<h1>" + props.name + "</h1> has completed the FTP Validation method for Facebook @ " + Date.now()
                        };

                       fetchData(appVars.smtpUrl,data);
                       setValidated("Validation Pending...");
                       setDisabled(true);
                       setCookie('validation',true,30);
                       setCopySuccess('Your validation request has been sent. You will be notified if the validation fails.');
                       setCopySuccessShow(true);
                       //console.log(data);
                    }}>{validated}</Button>
                </Grid>
              
            </Grid>

            <Snackbar
                open={copySuccessShow}
                autoHideDuration={10000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    {copySuccess}
                </Alert>
            </Snackbar>

        </div>
    );
};

DashBoard.propTypes = {

};

export default DashBoard;
